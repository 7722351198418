@use 'common'

.wrapper
	&.center
		text-align: center

.mobile
	display: inline

	@media (min-width: common.$break700)
		display: none

.desktop
	display: inline

	@media (max-width: common.$break700 - .1px)
		display: none
