@use 'common'

$mainImageWidth: 630

.wrapper
	&.is_hovered
		.mainImage
			&In
				transform: scale(1.02)

	&.view_split

		&.view
			&_offset
				&Left
					.mainImage
						margin-left: var(--HeroImages-offset)

				&Right
					.mainImage
						margin-right: var(--HeroImages-offset)

				@media (min-width: common.$break1400)
					&Left, &Right
						.mainImage
							margin-left: 0
							margin-right: 0

.mainImageCaption
	padding-left: 25px

.mainImage
	&In
		transition: transform 1s ease

.view_split
	--HeroImages-offset: var(--global-page-horizontal-spacing)
	--HeroImages-secondaryImage1-offset: calc(100%)
	--HeroImages-secondaryImage2-offset: calc(-100%)

	.mainImage
		$mainImageRatio: common.div(1, 1)
		$mainImage1AspectRatio: common.div(100%, $mainImageRatio)

		position: relative
		border-radius: 30px
		transform: translateZ(0) // fixes border radius while zooming in Safari

		&In
			padding-top: $mainImage1AspectRatio

	.secondaryImage1
		$secondaryImage1Ratio: common.div(160, 200)
		$secondaryImage1AspectRatio: common.div(100%, $secondaryImage1Ratio)

		width: clamp(80px, calc(common.div(160, $mainImageWidth) * 100%), 160px)
		top: 30px
		right: 100%
		border-radius: 20px
		transform: translateZ(0) translateX(100%)

		&In
			padding-top: $secondaryImage1AspectRatio

		@media (min-width: common.$break1400)
			transform: translateX(var(--HeroImages-secondaryImage1-offset))

	.secondaryImage2
		$secondaryImage2Ratio: common.div(100, 125)
		$secondaryImage2AspectRatio: common.div(100%, $secondaryImage2Ratio)

		width: clamp(50px, calc(common.div(100, $mainImageWidth) * 100%), 100px)
		left: 100%
		bottom: 50px
		transform: translateX(-100%)
		border-radius: 10px

		&In
			padding-top: $secondaryImage2AspectRatio

		@media (min-width: common.$break1400)
			transform: translateX(var(--HeroImages-secondaryImage2-offset))

	.secondaryImage1, .secondaryImage2
		position: absolute
		z-index: 1

	.mainImage, .secondaryImage1, .secondaryImage2
		overflow: hidden

		&In
			position: relative

	&.is_hovered

		.secondaryImage1

			@keyframes shakeImage1
				10%, 30%, 50%, 70%, 90%
					transform: translate(calc(var(--HeroImages-secondaryImage1-offset) - 1px))

				20%, 40%, 60%, 80%
					transform: translate(calc(var(--HeroImages-secondaryImage1-offset) + 1px))

		.secondaryImage2

			@keyframes shakeImage2
				10%, 30%, 50%, 70%, 90%
					transform: translate(calc(var(--HeroImages-secondaryImage2-offset) - 1px))

				20%, 40%, 60%, 80%
					transform: translate(calc(var(--HeroImages-secondaryImage2-offset) + 1px))

		.secondaryImage1, .secondaryImage2
			animation-duration: .8s

		.secondaryImage1
			animation-name: shakeImage1

		.secondaryImage2
			animation-name: shakeImage2

	@media (min-width: common.$break1400)
		--HeroImages-offset: 40px
		--HeroImages-secondaryImage1-offset: calc(100% - var(--HeroImages-offset))
		--HeroImages-secondaryImage2-offset: calc(-100% + var(--HeroImages-offset))

.view_fullImage
	height: 100%

	.mainImage
		height: 100%
		overflow: hidden

		&In
			height: 100%

		&::after
			content: ''
			position: absolute
			top: 0
			right: 0
			bottom: 0
			left: 0
			background-color: rgba(#000000, .35)
