@use 'common'

$authorSpacing: 10px
$colorWhite: #ffffff
$colorBlack: #000000
$fontBase: 20px

.wrapper
	// custom variable from Textual.module.sass file for paragraph
	--Textual-color: #{$colorWhite}
	--Textual-font-size-base: 16px

	max-width: 600px
	margin: 0 auto
	text-align: center
	color: $colorWhite
	position: relative
	svg
		position: absolute
		top: -20px
		left: 50%
		transform: translate(-50%, 0)
		width: 100%
		z-index: -1

	@media (min-width: common.$break480)
		--Textual-font-size-base: 20px

.tags
	position: relative
	z-index: 2
	margin-bottom: 1.5rem
	@media (min-width: common.$break768)
		margin-bottom: 40px

.title
	font-size: clamp(35px, .2em + 4vw, 60px)
	line-height: 1.23
	font-size: 2.2rem
	text-transform: uppercase
	margin: 0

	&, b
		font-weight: 900

	&.view_containsLongWord
		@media (max-width: common.$break360 - 1px)
			font-size: 30px

.perex
	max-width: 400px
	margin: 0 auto
	margin-top: 25px
	font-size: 1.2rem
	line-height: 1.4
	margin-top: 1.5rem
	@media (min-width: common.$break768)
		margin-top: 2rem

.authors
	display: grid
	grid-template-columns: repeat(auto-fit, minmax(100px, max-content))
	justify-content: center
	grid-gap: 1rem

.author
	// custom variable from AuthorBadge.module.sass file for label
	--AuthorBadge-label-color: #{$colorWhite}

.view_split
	--Textual-color: #{$colorBlack}
	color: $colorBlack
	gap: 3rem

	.author
		--AuthorBadge-label-color: initial

.view_fullImage
	padding-left: var(--global-page-horizontal-spacing)
	padding-right: var(--global-page-horizontal-spacing)
