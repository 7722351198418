.Wrapper
	// Keep in sync with Spacer.tsx
	$breakpoints: 360, 480, 560, 700, 1000, 1400
	$unit: 1px
	$height: calc(var(--spacer-height) * #{$unit} - 2px)

	--spacer-break-initial: 0
	--spacer-height: var(--spacer-break-initial)
	padding: 1px 0
	margin-top: min(0px, #{$height}) // Handles negative values

	&::before
		content: ''
		display: block
		margin-top: max(0px, #{$height}) // Handles positive values

	@each $breakpoint in $breakpoints
		@media (min-width: ($breakpoint * 1px))
			--spacer-height: var(--spacer-break-#{$breakpoint})
