@use './common'

.Wrapper
	display: flex

.Main
	display: flex
	align-items: stretch
	justify-content: stretch

.Content
	flex-grow: 1
	width: 100%
	height: 100%

.Main,
.Ratio
	width: 100%
	flex-basis: 100%
	flex-shrink: 0
	flex-grow: 0

.Ratio
	visibility: hidden
	pointer-events: none
	margin-left: -1000vw

.Placeholder
	display: block
	width: 100%
	padding-top: 100%
