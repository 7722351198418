@use 'common'

$verticalFullImageOffset: common.$header-height-estimate + 20px // $header-height-estimate = see in vars.sass, 20px = to make sure there is no white space

.wrapper
	display: block
	position: relative
	text-decoration: none

.main
	position: relative
	margin-bottom: 2rem
	@media (min-width: common.$break768)
		margin: 0 auto

.link
	display: block
	position: absolute
	top: 0
	left: 0
	right: 0
	bottom: 0

.view_split,
.view_fullImage
	.main
		display: flex
		height: 100%

.images
	width: 90%
	margin-inline: auto
	@media (min-width: common.$break768)
		position: absolute
		z-index: -1
		top: 0
		right: 0
		bottom: 0
		left: 0

.content
	width: 100%
	height: 100%
	display: flex
	gap: 1rem
	flex-direction: column
	justify-content: center
	align-items: center
	text-align: center

.view_split
	margin-top: 16px

	.main
		max-width: var(--global-content-width-normal)
		gap: 1rem
		flex-direction: column

	.content

	.images
		position: relative

	@media (min-width: common.$break768)
		.main
			flex-direction: row
			justify-content: space-between
			align-items: center

		.images,
		.content
			width: calc(50% - 5px)

	@media (min-width: common.$break1000)
		margin-top: 40px

.view_fullImage
	.main
		max-width: var(--global-content-width-wide-max)

	.images
		top: calc(-1 * $verticalFullImageOffset)

	.content
		padding-top: 250px
		padding-bottom: 25px

		@media (min-width: common.$break1000)
			padding: 0

.stickers
	$safe-space: 80
	overflow: hidden
	height: 100% + $safe-space
	top: calc(-1 * $verticalFullImageOffset)

	&, &Canvas
		pointer-events: none
		position: absolute
		left: 0
		width: 100%

	&Canvas
		height: 100% * common.div(100, 100 + $safe-space)
		top: $verticalFullImageOffset
